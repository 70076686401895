import React from 'react'

const TechContent = ({ contentSections }) => {
  const renderSections = sections => {
    return sections.map((section, index) => (

      <div key={index} className="row pb-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right">
            {section.title && (
              <div
                dangerouslySetInnerHTML={{ __html: section.title }}
              />
            )}
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          {section.description && (
            <div
              dangerouslySetInnerHTML={{ __html: section.description }}
            />
          )}
        </div>
      </div>

    ))
  }
  return renderSections(contentSections)
}

export default TechContent
