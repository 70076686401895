import React from 'react'
import JumbotronInfoPack from '../services/JumbotronInfoPack'

const TechJumbotron = ({
  colorAccentClass,
  image,
  title,
  infoPackUrl,
}) => {
  return (
    <section className={`tech-jumbotron bg-dark ${colorAccentClass}`}>
      <div className="container">
        <div className="row">
          <div className="d-none d-md-block col-md-4">
            <img src={image} alt={title} className="services-image" />
          </div>
          <div className="col-md-8">
            <article className="page-article">
              <p className="seo-h3">Development Services</p>
              <h1 className="text-white">{title}</h1>
              <JumbotronInfoPack infoPackUrl={infoPackUrl} />
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TechJumbotron
