import React from 'react'
import NavBar from '../components/common/NavBar'
import ContactBottom from '../components/contact/ContactBottom'
import TechContent from '../components/techs/TechContent'
import TechJumbotron from '../components/techs/TechJumbotron'
import MvpReasons from '../components/services/MvpReasons'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

const Tech = ({ data }) => {
  const { tech } = data
  const { servicesIlustrationWeb } = data
  const {
    email,
    phone,
    skype,
    phoneClean,
    skypeName,
    workTimeUTC,
  } = data.info_web.contact
  const { city, street, country, url } = data.info_web.address.office
  const { legalName } = data.info_web
  const mailingConfig = data.mailing_info_web
  return (
    <>
    <Helmet
      title={`${tech.title} | Zaven`}
      meta={[{ name: 'description', content: tech.description }]}
    />
    <NavBar />
    <article>
      <TechJumbotron
        colorAccentClass={'service-web'}
        image={servicesIlustrationWeb.fluid.src}
        title={tech.title}
        infoPackUrl={`${process.env.GATSBY_MAILING_URL}/api/infopack`}
      />
      <div className="container pt-5">
        {tech.content && (
          <div
            dangerouslySetInnerHTML={{ __html: tech.content }}
          />
        )}
        {tech.contentSections ? <TechContent contentSections={tech.contentSections} /> : {}}
      </div>
    </article>
    <aside>
      <MvpReasons />
      <ContactBottom
        city={city}
        skype={skype}
        street={street}
        country={country}
        legalName={legalName}
        phoneClean={phoneClean}
        workTimeUTC={workTimeUTC}
        skypeName={skypeName}
        phone={phone}
        email={email}
        addressUrl={url}
        mailingConfig={mailingConfig}
      />
    </aside>
    </>
  )
}

export default Tech

export const query = graphql`
  query TechQuery($slug: String!) {
    info_web: dataJson {
      ...Contact
    }
    tech: techsYaml(slug: { eq: $slug }) {
      ...Tech
    }
    servicesIlustrationWeb: imageSharp(
      fluid: { originalName: { regex: "/services_illustration_web/" } }
    ) {
      fluid(quality: 85) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
