import React from 'react'
 
const MvpReasons = () => (
 <section className="section bg-faded">
   <div className="container">
     <h2 className="text-center mt-2">
       Munissez votre startup d’ingénieurs full-stack
     </h2>
     <div className="card-group card-group-transparent my-2 mvp-reasons">
       <div className="card">
         <div className="card-body">
           Prototypage rapide
           <br />
           <em className="text-muted">
             <abbr title="Minimum Viable Product">MVP</abbr> fourni en 12-16 semaines
           </em>
         </div>
       </div>
       <div className="card">
         <div className="card-body">
           Plein soutien de l’équipe
           <br />
           <em className="text-muted">
             analyste, PM, programmeurs, concepteurs, testeurs
           </em>
         </div>
       </div>
       <div className="card">
         <div className="card-body">
           UI/UX jolie et conviviale pour l’utilisateur,<br/>
           <em className="text-muted">
            conçues par nos concepteurs
           </em>
         </div>
       </div>
       <div className="card">
         <div className="card-body">
           <h3 className="seo-strong m-0">Programmation agile</h3>
           <br />
           <em className="text-muted">gestion selon SCRUM</em>
         </div>
       </div>
       <div className="card">
         <div className="card-body">
           Soutien à long terme
           <br />
           <em className="text-muted">
             continuez à développer votre produit dans la période MVP
           </em>
         </div>
       </div>
       <div className="card">
         <div className="card-body">
           Gestion à distance conviviale
           <br />
           <em className="text-muted">
             pour
             <h3 className="seo-span">l’externalisation</h3> programmation sans frontières
           </em>
         </div>
       </div>
     </div>
   </div>
 </section>
)
 
export default MvpReasons
